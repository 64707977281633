<template>
  <div class="">
    <b-overlay :show="loading">
      <b-card>
        <b-form>
          <b-row>
            <b-col cols="12" v-for="(item, i) in data" :key="i">
              <b-form-group :label="item.name">
                <b-input-group>
                  <b-form-input v-model="item.komisi_persentase" placeholder="Input" />
                  <b-input-group-append>
                    <b-button variant="outline-secondary"> %</b-button>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-button type="submit" @click.prevent="submit(item)" variant="gradient-primary"> Simpan </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card>
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Target Komisi">
                <b-input-group>
                  <b-form-input v-model="target_komisi" placeholder="Masukkan Target Komisi" />
                  <b-input-group-append>
                    <b-button type="submit" @click.prevent="submitTarget()" variant="gradient-primary"> Simpan </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BOverlay } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      loading: false,
      form: {
        jumlah: null,
      },
      target_komisi: null,
      data: [],
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.$store
        .dispatch("komisi/getDataJenis")
        .then((res) => {
          this.loading = false;
          this.data = res;
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
    getDataTarget() {
      this.loading = true;
      this.$store
        .dispatch("komisi/getTargetKomisi")
        .then((res) => {
          this.loading = false;
          this.target_komisi = res.target_qty;
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
    submit(item) {
      this.loading = true;
      let payload = {
        type: item.name,
        komisi_persentase: item.komisi_persentase,
      };
      this.$store
        .dispatch("komisi/saveJenis", payload)
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            message: "Berhasil Menyimpan Komisi",
          });
          this.getData();
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
    submitTarget() {
      this.loading = true;
      let payload = {
        target_qty: this.target_komisi,
      };
      this.$store
        .dispatch("komisi/saveTargetKomisi", payload)
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            message: "Berhasil Menyimpan Target Komisi",
          });
          this.getDataTarget();
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
  },
  created() {
    this.getData();
    this.getDataTarget();
  },
};
</script>

<style></style>
